import React, { useCallback } from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import {
  setMinutes,
  setHours,
  isAfter,
  isSameDay,
  addDays,
  isSunday,
} from "date-fns";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

registerLocale("fr", fr);
setDefaultLocale("fr");

const algoliaKey = process.env.REACT_APP_ALGOLIA_KEY;
const algoliaId = process.env.REACT_APP_ALGOLIA_ID;
const placeholder = JSON.parse(process.env.REACT_APP_PLACEHOLDER);
const primaryColor = process.env.REACT_APP_PRIMARY_COLOR;

const Container = styled.div`
  font-family: "Lato", sans-serif;
  background: #fcfcfc url("/home.jpg") no-repeat center top;
  background-size: cover;
  width: 100%;
  padding: 2rem;
  min-height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    background: none;
    background-color: #fcfcfc;
  }

  input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum", "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 6.5px 11px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 1.5715 !important;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .react-datepicker {
    font-family: "Lato", sans-serif;
    color: #414141;
    font-size: 0.8rem;
    background-color: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 1rem;
    overflow: hidden;
  }
  .react-datepicker__navigation--next {
    border-left-color: #ccc;
  }
  .react-datepicker__month-container .react-datepicker__header {
    border-bottom: 1px solid #d3d3d3;
    border-top-left-radius: 1rem;
    border-top-right-radius: 0;
  }
  .react-datepicker__time-container .react-datepicker__header {
    border-bottom: 1px solid #d3d3d3;
    border-top-left-radius: 0;
    border-top-right-radius: 1rem;
  }
  .react-datepicker__day--today {
    color: ${primaryColor};
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    background-color: ${primaryColor};
    color: #fff;
  }
  .react-datepicker__day--in-range {
    background-color: #eb7c7c;
    color: #fff;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  max-width: 980px;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 1.4rem;
  border-radius: 1rem;
  text-align: left;
  @media (max-width: 768px) {
    border-radius: 0;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const ColPlaces = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  @media (max-width: 576px) {
    flex-direction: column;
  }
`;
const ColDates = styled.div`
  width: 320px;
  flex: none;
  display: flex;
  flex-direction: row;
  @media (max-width: 576px) {
    flex-direction: column;
    width: auto;
  }
  @media (min-width: 576px) and (max-width: 768px) {
    width: auto;
  }
`;
const Place = styled.div`
  flex: 0.5;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    flex: 1;
    margin-bottom: 1rem;
  }
`;
const DateHour = styled.div`
  width: 160px;
  flex: none;
  margin-bottom: 1rem;
  @media (max-width: 576px) {
    margin-bottom: 1rem;
  }
  @media (min-width: 576px) and (max-width: 768px) {
    width: auto;
    margin-bottom: 1rem;
  }
`;
const InputWrapper = styled.label`
  display: block;
`;
const Label = styled.span`
  font-size: 0.8rem;
  color: #ffffff;
  display: block;
  margin-bottom: 6px;
`;
const ActionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 1rem;
  @media (max-width: 640px) {
    flex-direction: column;
    margin-top: 0;
  }
`;
const Help = styled.div`
  flex: 1;
  font-size: 0.8rem;
  color: #ffffff;
  @media (max-width: 640px) {
    margin-bottom: 1rem;
  }
`;
const Button = styled.button`
  width: 280px;
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${primaryColor} !important;
  border-radius: 6px;
  height: 48px;
  color: #ffffff;
  text-decoration: none;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1rem;
  border: none;
  outline: 0 none;
  cursor: pointer;
  line-height: 48px;
  &:hover {
    background-color: #000000;
    color: #ffffff !important;
  }
  @media (max-width: 420px) {
    width: 100%;
  }
`;
const Error = styled.div`
  color: ${primaryColor};
  font-size: 0.8rem;
  line-height: 1rem;
  padding: 3px 0;
`;
const DateFooter = styled.div`
  clear: both;
  padding: 10px;
  text-align: center;
  border-top: 1px solid #d3d3d3;
  background: #f6f6f6;
`;
const DateFooterButton = styled.button`
  border: 1px solid #aaabaa;
  outline: 0 none;
  cursor: pointer;
  padding: 6px 12px;
  background-color: #d3d3d3;
  border-radius: 6px;
`;

const initialState = {
  fromAlgolia: {},
  toAlgolia: {},
  fromDate: null,
  toDate: null,
  maxFromTimes: setHours(setMinutes(new Date(), 0), 18),
  minToTimes: setHours(setMinutes(new Date(), 0), 9),
  prune: true, // the form hasn't been submited
  errors: {
    fromAlgolia: "",
    toAlgolia: "",
    fromDate: "",
    toDate: "",
  },
};

// Algolia autocomple default options
const autocompleteOptions = {
  countries: ["fr", "fr"],
  apiKey: algoliaKey,
  appId: algoliaId,
  templates: {
    value: (suggestion) => {
      return `${suggestion.name}, ${suggestion.country}`;
    },
  },
};

// Datepickers' default props
const datepickerProps = {
  selectsStart: true,
  dateFormat: "dd/MM/yyyy k:mm",
  showTimeSelect: true,
  timeFormat: "k:mm",
  timeIntervals: 30,
  timeCaption: "Heure",
  showPopperArrow: false,
  popperPlacement: "auto",
  popperModifiers: { offset: { enabled: true, offset: "20px, 0" } },
};

const ValidationMessage = ({ message, prune }) => {
  if (prune === false && message) return <Error>{message}</Error>;

  return null;
};

const searchReducer = (state, action) => {
  let local = { ...state };

  switch (action.type) {
    case "set-from-place": {
      local.fromAlgolia = action.payload;
      local.toAlgolia = action.payload;

      if (state.fromDate === null && state.toDate === null) {
        const fromDate = setHours(setMinutes(addDays(new Date(), 7), 0), 9);
        const toDate = setHours(fromDate, 18);
        local.fromDate = fromDate;
        local.toDate = toDate;
      }

      break;
    }

    case "clear-from-place": {
      local.fromAlgolia = {};
      break;
    }

    case "set-to-place": {
      local.toAlgolia = action.payload;
      break;
    }

    case "clear-to-place": {
      local.toAlgolia = {};
      break;
    }

    case "update":
      for (let [key, value] of Object.entries(action.payload)) {
        local[key] = value;
      }
      break;

    case "validation":
      for (let [key] of Object.entries(local.errors)) {
        local.errors[key] = "";
        if (action.payload.hasOwnProperty(key))
          local.errors[key] = action.payload[key];
      }
      local.prune = false;
      break;

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }

  return local;
};

let fromAutocomplete;
let toAutocomplete;

const Form = React.forwardRef((props, ref) => {
  const { params, callback } = props;
  const [state, dispatch] = React.useReducer(searchReducer, initialState);
  const fromRef = React.useRef();
  const toRef = React.useRef();
  const fromDateRef = React.useRef();
  const toDateRef = React.useRef();

  React.useEffect(() => {
    if (state.prune === true) {
      // Initialize algolia
      const places = require("places.js");

      fromAutocomplete = places({
        ...autocompleteOptions,
        container: fromRef.current,
      });

      toAutocomplete = places({
        ...autocompleteOptions,
        container: toRef.current,
      });

      fromAutocomplete.on("change", (e) => {
        toAutocomplete.setVal(e.suggestion.value);

        dispatch({
          type: "set-from-place",
          payload: e.suggestion,
        });
      });

      fromAutocomplete.on("clear", () => {
        dispatch({ type: "clear-from-place" });
      });

      toAutocomplete.on("change", (e) => {
        dispatch({ type: "set-to-place", payload: e.suggestion });
      });

      toAutocomplete.on("clear", () => {
        dispatch({ type: "clear-to-place" });
      });

      // Set initial values
      if (params !== undefined) {
        // Set autocomplete values
        fromAutocomplete.setVal(params.fromAlgolia.value);
        toAutocomplete.setVal(params.toAlgolia.value);
        // update state
        dispatch({
          type: "update",
          payload: {
            fromAlgolia: params.fromAlgolia,
            toAlgolia: params.toAlgolia,
            fromDate: params.fromDate,
            toDate: params.toDate,
            maxFromTimes: params.maxFromTimes,
            minToTimes: params.minToTimes,
            prune: false,
          },
        });
      } else {
        fromAutocomplete.setVal(placeholder.value);
        toAutocomplete.setVal(placeholder.value);

        let defaultDate = setHours(setMinutes(addDays(new Date(), 7), 0), 9);
        if (isSunday(defaultDate)) defaultDate = addDays(defaultDate, 1);
        const toDate = setHours(setMinutes(defaultDate, 0), 18);

        dispatch({
          type: "update",
          payload: {
            fromAlgolia: placeholder,
            toAlgolia: placeholder,
            fromDate: defaultDate,
            toDate: toDate,
            maxFromTimes: setHours(setMinutes(defaultDate, 0), 8),
            minToTimes: toDate,
          },
        });
      }
    }
  }, [params, state.prune]);

  const handleDateChangeRaw = useCallback((e) => {
    e.preventDefault();
  }, []);

  const dateFromOnChange = useCallback(
    (date) => {
      let fromDate = date;

      // Set default start time to 9:00 the first time
      if (state.fromDate === null) {
        fromDate = setHours(setMinutes(date, 0), 9);
      }

      if (state.toDate !== null) {
        if (isAfter(fromDate, state.toDate)) {
          dispatch({
            type: "update",
            payload: {
              fromDate,
              toDate: setHours(setMinutes(fromDate, 0), 18),
              minToTimes: fromDate,
            },
          });
        } else if (isSameDay(fromDate, state.toDate)) {
          dispatch({
            type: "update",
            payload: {
              fromDate,
              maxFromTimes: state.toDate,
              minToTimes: fromDate,
            },
          });
        } else {
          dispatch({
            type: "update",
            payload: { fromDate, minToTimes: fromDate },
          });
        }
      } else {
        dispatch({
          type: "update",
          payload: { fromDate, minToTimes: setHours(setMinutes(date, 0), 8) },
        });
      }
    },
    [state.fromDate, state.toDate]
  );

  const dateToOnChange = useCallback(
    (date) => {
      let toDate = date;
      let minToTimes = setHours(setMinutes(new Date(), 0), 8);

      if (state.toDate === null) {
        toDate = setHours(setMinutes(toDate, 0), 9);
        if (state.fromDate !== null && isSameDay(state.fromDate, toDate)) {
          toDate = setHours(setMinutes(toDate, 0), 18);
        }
      }

      if (state.fromDate !== null && isSameDay(toDate, state.fromDate)) {
        minToTimes = state.fromDate;
      }

      dispatch({ type: "update", payload: { toDate, minToTimes } });
    },
    [state.fromDate, state.toDate]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      let errors = {};
      // Validate
      if (state.fromAlgolia.hasOwnProperty("value") === false)
        errors.fromAlgolia = "Veuillez saisir la ville de départ";

      if (state.toAlgolia.hasOwnProperty("value") === false)
        errors.toAlgolia = "Veuillez saisir la ville de retour";

      if (state.fromDate === null)
        errors.fromDate = "Veuillez saisir la date de départ";

      if (state.toDate === null)
        errors.toDate = "Veuillez saisir la date de retour";

      dispatch({
        type: "validation",
        payload: errors,
      });

      if (Object.keys(errors).length > 0) return;

      if (typeof callback === "function") {
        callback({ ...state });
      }
    },
    [state, callback]
  );

  return (
    <form id="dms-moteur" action="/resultats-recherche" method="POST" ref={ref}>
      <Container>
        <Wrapper>
          <Row>
            <ColPlaces>
              <Place>
                <InputWrapper>
                  <Label>Lieu de départ ou adresse</Label>
                  <input
                    ref={fromRef}
                    type="search"
                    aria-label="Lieu de départ"
                    id="ville-depart"
                  />
                </InputWrapper>
                <ValidationMessage
                  message={state.errors.fromAlgolia}
                  prune={state.prune}
                />
              </Place>
              <Place>
                <InputWrapper>
                  <Label>Lieu de retour</Label>
                  <input
                    ref={toRef}
                    type="search"
                    aria-label="Lieu de retour"
                    id="ville-retour"
                  />
                </InputWrapper>
                <ValidationMessage
                  message={state.errors.toAlgolia}
                  prune={state.prune}
                />
              </Place>
            </ColPlaces>
            <ColDates>
              <DateHour>
                <InputWrapper>
                  <Label>Date de départ</Label>
                  <DatePicker
                    {...datepickerProps}
                    selectsStart
                    selected={state.fromDate}
                    onChange={dateFromOnChange}
                    startDate={state.fromDate}
                    endDate={state.toDate}
                    minDate={new Date()}
                    minTime={setHours(setMinutes(new Date(), 0), 8)}
                    maxTime={state.maxFromTimes}
                    onChangeRaw={handleDateChangeRaw}
                    ref={fromDateRef}
                  >
                    <DateFooter>
                      <DateFooterButton
                        onClick={(e) => {
                          e.preventDefault();
                          fromDateRef.current.setState({
                            ...fromDateRef.current.state,
                            open: false,
                          });
                        }}
                      >
                        OK
                      </DateFooterButton>
                    </DateFooter>
                  </DatePicker>
                </InputWrapper>
                <ValidationMessage
                  message={state.errors.fromDate}
                  prune={state.prune}
                />
              </DateHour>
              <DateHour>
                <InputWrapper>
                  <Label>Date de retour</Label>
                  <DatePicker
                    {...datepickerProps}
                    selectsEnd
                    selected={state.toDate}
                    onChange={dateToOnChange}
                    startDate={state.fromDate}
                    endDate={state.toDate}
                    minDate={
                      state.fromDate === null ? new Date() : state.fromDate
                    }
                    minTime={state.minToTimes}
                    maxTime={setHours(setMinutes(new Date(), 0), 18)}
                    onChangeRaw={handleDateChangeRaw}
                    ref={toDateRef}
                  >
                    <DateFooter>
                      <DateFooterButton
                        onClick={(e) => {
                          e.preventDefault();
                          toDateRef.current.setState({
                            ...toDateRef.current.state,
                            open: false,
                          });
                        }}
                      >
                        OK
                      </DateFooterButton>
                    </DateFooter>
                  </DatePicker>
                </InputWrapper>
                <ValidationMessage
                  message={state.errors.toDate}
                  prune={state.prune}
                />
              </DateHour>
            </ColDates>
          </Row>
          <ActionRow>
            <Help>
              Comparez les prix par adresse ! Lancez une recherche dans le
              moteur avec votre adresse.
            </Help>

            <Button onClick={(e) => handleSubmit(e)}>
              Comparer les prix{" "}
              <ArrowRightOutlined style={{ marginLeft: "6px" }} />
            </Button>
          </ActionRow>
        </Wrapper>
      </Container>
    </form>
  );
});

export default Form;
