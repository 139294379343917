import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

const googleVerifID = process.env.REACT_APP_GOOGLEVERIF;

function SEO({ description, lang, meta, title }) {
  const metaDescription = description;

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={title}
      meta={[
        { name: "description", content: metaDescription },
        { property: "og:title", content: title },
        { property: "og:description", content: metaDescription },
        { property: "og:type", content: "website" },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:creator", content: "Digital Mkt Services" },
        { name: "twitter:title", content: title },
        { name: "twitter:description", content: metaDescription },
        { name: "google-site-verification", content: googleVerifID },
      ].concat(meta)}
    ></Helmet>
  );
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
